import 'bootstrap/dist/css/bootstrap.css';

const params = new URLSearchParams(document.location.search.slice(1));
const wiki = params.get('wiki');
const comment = params.get('comment');
const post = params.get('post');

document.querySelector('#copyToClipboard').addEventListener('click', (event) => {
  if (!navigator.clipboard) {
    console.error('clipboard api not available');
    return;
  }
  const text = document.getElementById('content').innerText;
  try {
    navigator.clipboard.writeText(text);
    event.target.textContent = 'copied to clipboard!';
  } catch (err) {
    console.error('Failed to copy!', err);
  }
});

const rendderResult = (data) => {
  const htmlOut = document.getElementById('content');
  htmlOut.innerText = data;
  document.getElementById('copyToClipboard').style.display = 'block';
};

const getData = (async (type, url) => {
  let output;
  await fetch(url).then((res) => res.json().then((data) => {
    switch (type) {
      case 'post':
        output = data[0].data.children[0].data.selftext;
        break;
      case 'comment':
        output = data[1].data.children[0].data.body;
        break;
      case 'wiki':
        output = data.data.content_md;
        break;
      default:
        output = 'meh. error.';
    }
  })).catch((err) => output = err.toString());
  return output;
});

const validateUrl = (((url) => {
  const www = new URL(url);
  if (!www.hostname.startsWith('www')) {
    www.hostname = 'www.reddit.com';
  }

  if (!www.pathname.endsWith('.json')) {
    www.pathname += '.json';
  }

  return www.href;
}));

document.getElementById('postform').addEventListener('submit', async (event) => {
  event.preventDefault();
  const formData = new FormData(document.querySelector('form'));
  for (const pair of formData.entries()) {
    if (pair[1].length !== 0) {
      const url = validateUrl(pair[1]);
      // const data = getData(pair[0], url);
      await getData(pair[0], url).then((data) => {
        rendderResult(data);
      });
    }
  };
});

if (wiki) {
  getData('wiki', validateUrl(wiki)).then((data) => {
    document.write(data);
  });
}
if (comment) {
  getData('comment', validateUrl(comment)).then((data) => {
    document.write(data);
  });
}
if (post) {
  getData('post', validateUrl(post)).then((data) => {
    document.write(data);
  });
}
